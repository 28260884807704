<template>
  <div class="row">
    <div class="col-md-12">
      <div class="bgc-white bd bdrs-3 p-20 mB-20">
        <h2 class="c-grey-900 mB-20" style="display: inline-block">
          {{ $t("clients") }}
        </h2>
        <div id="dataTable_wrapper" class="dataTables_wrapper">
          <form @submit="checkForm">
            <div class="form-group row pB-30 pT-30">
              <div class="col-sm-4">
                <input type="text" class="form-control" v-model="key" :placeholder="this.$t('clientSearch')" />
              </div>
              <div v-if="branches.length != 0" class="form-group col-md-3">
                <select id="inputState" class="form-control" v-model="branch_id" :placeholder="$t('branch')">
                  <option value="" disabled selected>{{ $t('clientsBranch') }}</option>
                  <option value="">{{ $t('all') }}</option>
                  <option v-for="item in branches" :key="item.id" :value="item.id">{{ getTranslation(item.ar_name , item.en_name) }}</option>
                </select>
              </div>
              <div class="col-sm-2">
                <button type="submit" class="btn btn-primary mL-10">
                  {{ $t("search") }}
                </button>
              </div>
            </div>
          </form>
          <table id="dataTable" class="table table-bordered dataTable table-hover" role="grid" aria-describedby="dataTable_info" style="width: 100%" width="100%" cellspacing="0">
            <thead>
              <tr role="row">
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" style="width:50px" aria-label="Position: activate to sort column ascending">
                  {{ $t("#") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending">
                  {{ $t("name") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Office: activate to sort column ascending">
                  {{ $t("mobileNumber") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Office: activate to sort column ascending">
                  {{ $t("email") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Office: activate to sort column ascending">
                  {{ $t("qID") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Office: activate to sort column ascending">
                  {{ $t("parkingNumber") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Office: activate to sort column ascending" style="width: 100px">
                  {{ $t("remainingDays") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Office: activate to sort column ascending" style="width: 100px">
                  {{ $t("subscriptions") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" style="width: 100px" aria-label="Start date: activate to sort column ascending">
                  {{ $t('clientInformation') }}
                </th>
              </tr>
            </thead>
            <tbody v-show="!loading" v-for="item in clients" :key="item.id">
              <tr role="row" class="odd">
                <td>
                  {{item.id }}
                </td>
                <td>
                  {{item.full_name +' '}}<a @click="editClient(item)" href="javascript:"
                    ><span
                      class="
                        badge
                        bgc-orange-50
                        c-orange-700
                        p-10
                        lh-0
                        tt-c
                        badge-pill
                      "
                      ><i class="ti ti-pencil"></i></span
                    ></a
                  >
                </td>
                <td style="direction: ltr;">
                  {{ item.mobile_number}}
                </td>
                <td style="direction: ltr;">
                  {{ item.email == '' ? '-' : item.email}}
                </td>
                <td>
                  <img v-if="item.q_id_file == null" src="@/assets/static/images/warning.png" width="20" class="mL-5 mR-5" />
                  <span class="link" @click="showQID(item)">{{item.q_id}}</span>
                </td>
                <td>{{ item.parking_number == '' ? '-' :item.parking_number  }}</td>
                <td v-if="item.subscription.length":style="'color:'+(item.subscription[0].status == 'active' ? 'green' : item.subscription[0].status == 'about finish' ? 'orange' : 'red')">
                  {{ item.subscription[0].days == 0 ? $t('expired') : item.subscription[0].days + ' '+$t('day')}}
                </td>
                <td v-else> - </td>
                <td class="link" @click="showSubscriptions(item)">
                  <img v-if="item.subscription_count == 0" src="@/assets/static/images/warning.png" width="20" class="mL-5 mR-5" />
                  {{ item.subscription_count+' '+$t('subscription')}}
                </td>
                <td>
                  <button type="button" @click="showClientInfo(item)" class="btn btn-primary">{{ $t('showInfo') }}</button>
                </td>
              </tr>
            </tbody>
          </table>
          <loading v-show="loading" />
          <p v-show="!clients.length" style="text-align: center">
            {{ $t("noData") }}
          </p>
          <div style="text-align:center;margin-top:40px">
            <pagination style="display:inline-block;text-align:center" v-model="page" :records="pagination.total ?pagination.total : 0 " :per-page="40" @paginate="getClients" />
          </div>
        </div>
      </div>
    </div>
    <div class="modal fade" id="qid" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div v-if="getLang() === 'ar'" class="modal-header" style="direction: rtl;">
            <h5 class="modal-title text-right" id="exampleModalLongTitle">{{ $t('qID') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; left: 15px; top: 15px;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-else class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">{{ $t('qID') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-sm-9">
                <input type="text" @input="handleInput" class="form-control mB-10" id="qID" :disabled='disableQID' v-model="qID" :placeholder="this.$t('qID')" />
              </div><div class="col-sm-1">
                  <sui-button @click="editQidFunction" icon="edit" />
              </div><div class="col-sm-1">
                  <sui-button @click="copyQID"  icon="copy" />
              </div>
            </div>
            <div v-show="qIDError" class="alert alert-danger" role="alert">
          {{ $t('qIDError') }}
        </div>
            <div v-if="!pdfSrc" class="row">
              <div class="col-sm-12">
                <div class="file-upload-container" @click="openFileInput" @dragover.prevent="handleDragOver" @drop="handleDrop">
                  <img src="@/assets/static/images/add-file.png" width="100" />
                  <i class="fas fa-file-upload"></i>
                  <input type="file" ref="fileInput" class="file-input" @change="handleFileUpload" accept=".pdf" />
                </div>
              </div>
              <!-- Square space with dashed gray border -->
            </div>
            <div>
              <div v-if="pdfSrc" class="mT-20 pdf-container"  @mouseout="hide" @mouseover="display">
                <!-- Download and Edit icons -->
                <div v-show='isYVisible' class="pdf-icons">
                  <input type="file" ref="fileInput" class="file-input" @change="handleFileUpload" accept=".pdf" />
                  <button @click="openFileInput" class="btn btn-primary mL-10 mR-10">
                    <i style="font-size: 30px; margin: 0 !important;" class="ti-pencil  mL-5"></i>
                  </button>
                  <button v-if="!file" @click="downloadPdf" class="btn btn-primary mL-10 mR-10">
                    <i style="font-size: 30px; margin: 0 !important;" class="ti-download  mL-5"></i>
                  </button>
                  <button v-else @click="removeFile" class="btn btn-primary mL-10 mR-10">
                    <i style="font-size: 30px; margin: 0 !important;" class="ti-close  mL-5"></i>
                  </button>
                </div>
                <!-- PDF viewer with dashed border -->
                <div class="pdf-viewer-container" style="overflow: hidden;">
                  <div v-show='isYVisible' class="pdf-shadow-layer"></div>
                  <pdf :src="pdfSrc" :page="1" class="pdf-viewer"></pdf>
                </div>
              </div>
            </div>
          </div>
          <div  v-if="!popupLoading" class="modal-footer">
            <button v-if="file || isUpdated" @click="updateQID" type="button" class="btn btn-primary">{{ $t('save') }}</button>
              <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('close') }}</button>
          </div>
          <div v-else class="modal-footer">
              <div><loading /></div>
            </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="clientInfo" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-dialog-centered" role="document">
        <div class="modal-content">
          <div v-if="getLang() === 'ar'" class="modal-header" style="direction: rtl;">
            <h5 class="modal-title text-right" id="exampleModalLongTitle">{{ $t('clientInformation') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; left: 15px; top: 15px;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-else class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">{{ $t('clientInformation') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div class="modal-body" style="height:10% !important">
            <table id="dataTable" class="table table-bordered dataTable table-hover" role="grid" aria-describedby="dataTable_info" style="width: 100%" width="100%" cellspacing="0">
            <tbody  v-for="index in 12" :key="index">
              <tr role="row" class="odd">
                <td>
                  {{ $t('q'+(index))}}
                </td>
                <td>
                  <sui-icon v-if="tempClientInfo['q'+index] == '1'" name="check" color="green" />
                  <sui-icon v-else name="close" color="red" />
                </td>
              </tr>
            </tbody>
            </table>
          </div>
          <div class="modal-footer">
            <button type="button" class="btn btn-secondary" data-dismiss="modal">{{ $t('close') }}</button>
          </div>
        </div>
      </div>
    </div>

    <div class="modal fade" id="subscriptions" data-backdrop="static" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
      <div class="modal-dialog modal-lg" role="document">
        <div class="modal-content">
          <div v-if="getLang() === 'ar'" class="modal-header" style="direction: rtl;">
            <h5 class="modal-title text-right" id="exampleModalLongTitle">{{ $t('subscriptions') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close" style="position: absolute; left: 15px; top: 15px;">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-else class="modal-header">
            <h5 class="modal-title" id="exampleModalLongTitle">{{ $t('subscriptions') }}</h5>
            <button type="button" class="close" data-dismiss="modal" aria-label="Close">
              <span aria-hidden="true">&times;</span>
            </button>
          </div>
          <div v-if="!isAdd" class="modal-body" style="height:10% !important">
            <table v-if="temp.length" id="dataTable" class="table table-bordered dataTable table-hover" role="grid" aria-describedby="dataTable_info" style="width: 100%" width="100%" cellspacing="0">
              <thead>
              <tr role="row">
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending">
                  {{ $t("payDate") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending">
                  {{ $t("startDate") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending">
                  {{ $t("endDate") }}
                </th>
                <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending">
                  {{ $t("coach") }}
                </th>
              <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending">
                  {{ $t("price") }}
                </th>
              <th class="sorting" tabindex="0" aria-controls="dataTable" rowspan="1" colspan="1" aria-label="Position: activate to sort column ascending">
                  {{ $t("remainingDays") }}
                </th>
              </tr>
            </thead>
              <tbody  v-for="item in temp" :key="item.id" :value="item.id">
              <tr role="row" class="odd">
                <td>
                  {{ formatDate(item.created_at)}}
                </td>
                <td>
                  {{ formatDate(item.start_at)}}
                </td>
                <td >
                  {{ formatDate(item.end_at)+' '}}<a @click="editEndDate(item)" href="javascript:"
                    ><span
                      class="
                        badge
                        bgc-orange-50
                        c-orange-700
                        p-10
                        lh-0
                        tt-c
                        badge-pill
                      "
                      ><i class="ti ti-pencil"></i></span
                    ></a
                  >
                </td>
                <td>
                  {{ item.coach == null ? '-' : getTranslation(item.coach.ar_name, item.coach.en_name)}}
                </td>
                <td style="color:green">
                  {{ getTranslation(item.subscription_type.ar_name , item.subscription_type.en_name) +' ('+item.price+' '+$t('QAR')+')'}}
                </td>
                <td :style="'color:'+(item.status == 'active' ? 'green' : item.status == 'about finish' ? 'orange' : 'red')">
                  {{ item.days == 0 ? $t('expired') : item.days + ' '+$t('day')}}
                </td>
              </tr>
            </tbody>
            </table>
            <h1 v-else style="text-align:center">{{$t('noSubscriptions')}}</h1>
          </div>
          <div v-else-if="isEditEndDate" class="modal-body" style="height:10% !important">
            <div class="row">
              <div class="col-sm-12">
                <input type="date" class="form-control" id="inputEmail4" v-model="endDate" required />
              </div>
            </div>
          </div>
          <div v-else class="modal-body" style="height:10% !important">
            <div class="row">
              <div class="col-sm-5"><sui-dropdown selection :options="getSubscriptionsTypes()" v-model="subscriptionType" :placeholder="this.$t('subscription')" /></div>
              <div class="col-sm-5">
                <sui-dropdown selection :options="getCoaches()" v-model="coach" :placeholder="this.$t('personalTrainerName')" />
              </div>
                <div class="col-sm-2"><button :disabled="subscriptionType == null" type="button" class="btn btn-primary" style="margin-top:5px" @click="subscribe">{{ $t('add') }}</button></div>
            </div>
            <div class="row" style="margin-top:10px">
              <div class="form-group col-md-6">
              <label for="inputEmail4">{{ $t("startDate")  + ' ('+$t('optional')+')'}}</label
              ><input type="date" class="form-control" id="inputEmail4" v-model="startDate" required />
            </div>
        </div>
          </div>
          <div v-if="popupLoading" class="modal-footer">
            <loading/>
          </div>
          <div v-else class="modal-footer">
            <button v-if="!isAdd" type="button" class="btn btn-primary" @click="addSubscription">{{ $t('addSubscription') }}</button>
            <button v-else-if="isEditEndDate" type="button" class="btn btn-primary" @click="updateEndDate">{{ $t('edit') }}</button>
            <button v-if="isAdd" type="button" class="btn btn-secondary" @click="isAdd=false;isEditEndDate=false;">{{ $t('back') }}</button>
            <button type="button" class="btn btn-secondary" data-dismiss="modal" >{{ $t('close') }}</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import {pdfProxy } from '../../../config/proxyConfig';;
export default {
    data() {
      return {
        pdfSrc: null,
        loading: true,
        popupLoading: false,
        statusLoading: true,
        key: "",
        clients: [],
        pagination: {},
        branches: [],
        qID: '',
        page: 1,
        isYVisible: false,
        disableQID: true,
        clientID : '-1',
        file: null,
        isUpdated: false,
        qPdfFile: '',
        sql : '',
        qIDError: false,
        tempClientInfo: {},
        temp: [],
        isAdd: false,
        subscriptionsTypes: [],
        subscriptionType: null,
        coaches:[],
        clientID: -1,
        coach: null,
        startDate: '',
        subscription: {},
        isEditEndDate:false,
        endDate:'',
      };
    },
    created() {
      this.getClients();
    },
    methods: {
      editClient: function (item) {
        const panel1Handle = this.$showPanel({
          component: () => import("../../views/clients/EditClient.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            reload:this.getOffers,
            client:item
          },
        });
      },
      editEndDate(item) {
        this.subscription = item;
        const dateObject = new Date(item.end_at);
        const dateOnly = dateObject.toISOString().split("T")[0];
        this.endDate = dateOnly;
        this.isEditEndDate = true;
        this.isAdd = true;
      },
      speak() {
      const synth = window.speechSynthesis;
      const utterance = new SpeechSynthesisUtterance('hello Ahmed Karmala');
      synth.speak(utterance);
    },
      getSubscriptionsTypes() {
        var subscriptionsTypes = [];
          this.subscriptionsTypes.forEach((subscriptionType) => {
            subscriptionsTypes.push({
              text: (this.getTranslation(subscriptionType.ar_name, subscriptionType.en_name)+' ('+subscriptionType.price+' '+this.$t('QAR')+')'),
              value: subscriptionType.id
            });
          });
          return subscriptionsTypes;
      },
      getCoaches: function() {
          var coaches = [];
          this.coaches.forEach((coach) => {
            coaches.push({
              text: (this.getTranslation(coach.ar_name, coach.en_name)),
              value: coach.id
            });
          });
          return coaches;
        },
      addSubscription() {
        this.isAdd = !this.isAdd;
      },
      copyQID() {
       // Create a temporary input element
      const input = document.getElementById('qID');

      //undisable input
      input.disabled = false;

      // Select the text in the input
      input.select();

      // Execute the copy command
      document.execCommand('copy');

      //undisable input
      input.disabled = this.disableQID;

      // Provide user feedback
      alert(this.$t('copy'));
    },
      editQidFunction() {
        this.disableQID = false;
      },
      formatDate(inputDateString) {
        const months = ["JAN", "FEB", "MAR", "APR", "MAY", "JUN", "JUL", "AUG", "SEP", "OCT", "NOV", "DEC"];
        const arMonths = ["يناير", "فبراير", "مارس", "أبريل", "مايو", "يونيو", "يوليو", "أغسطس", "سبتمبر", "أكتوبر", "نوفمبر", "ديسمبر"];
        const date = new Date(inputDateString);
        const day = date.getDate();
        const month = localStorage.getItem('lang') == 'ar' ? arMonths[date.getMonth()] : months[date.getMonth()];
        const year = date.getFullYear();
        return `${day} ${month} ,${year}`;
      },
      downloadPdf() {

const dynamicPdfUrl = 'https://forfit.nubian-code.com/public/q_id/'+this.qPdfFile;

window.open(dynamicPdfUrl, '_blank');
      },
      getRole() {
        return sessionStorage.getItem('role');
      },
      showSubscriptions(item) {
        this.startDate = "";
        this.temp = item.subscription;
        this.isAdd = false;
        this.coach = item.client_selected_coach == null ? null : item.client_selected_coach.coach_id;
        this.clientID = item.id;
        $('#subscriptions').modal('show')
      },
      showQID(item) {
        this.pdfSrc = null;
        this.qIDError = false;
        this.isUpdated = false;
        this.disableQID = true;
        this.qID = item.q_id;
        this.clientID = item.id;
        this.qPdfFile = item.q_id_file
        if(item.q_id_file != null) {
          this.uploadFileUrl('/public/q_id/'+item.q_id_file);
        }
        this.file = null;
        $('#qid').modal('show')
      },
      showClientInfo(item) {
        this.tempClientInfo = item.client_information;
        $('#clientInfo').modal('show')
      },
      removeFile(item) {
        this.file = null;
        this.uploadFileUrl('/pdf'+this.qPdfFile);
      },
      getTranslation(ar, en) {
        return localStorage.getItem('lang') == 'ar' ? ar : en;
      },
      checkForm: function(e) {
        e.preventDefault();
        this.getClients();
      },
      openFileInput() {
        // Trigger the file input when the container is clicked
        this.$refs.fileInput.click();
      },
      handleFileUpload(event) {
        // Handle file upload logic here
        const file = event.target.files[0];
        this.uploadFile(file);
      },
      handleDragOver(event) {
        // Prevent default behavior to enable dropping
        event.dataTransfer.effectAllowed = 'copy';
        event.dataTransfer.dropEffect = 'copy';
      },
      handleDrop(event) {
        // Prevent default behavior and handle dropped files
        event.preventDefault();
        const file = event.dataTransfer.files[0];
        this.uploadFile(file);
      },
      uploadFile(file) {
        const reader = new FileReader();
        this.file = file;
        reader.onload = (e) => {
          this.pdfSrc = e.target.result;
        };
        reader.readAsDataURL(file);
      },
      uploadFileUrl(url) {
        this.pdfSrc = url;
        console.log('url : ',url);
      },
      handleInput() {
        this.isUpdated = true;
      // Remove non-numeric characters
      this.qID = this.qID.replace(/\D/g, '');

      // Limit the length to 11 characters
      if (this.qID.length > 11) {
        this.qID = this.qID.slice(0, 11);
      }
      else if(this.qID.length == 11) {
        this.qIDError =false;
      }
    },
      getClients: function() {
        this.loading = true;
        this.$http.get("/backend/clients/get", {
          headers: {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          },
          params: {
            key: this.key,
          },
        }).then(function(data) {
            this.loading = false;
            switch (data.status) {
              case 200:
                this.clients = data.body.clients.data;
                this.pagination = data.body.clients;
                this.subscriptionsTypes = data.body.subscriptions_types;
                this.coaches = data.body.coaches
                break;
            }
          },
          (err) => {
            this.loading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          });
      },
      updateQID: async function() {
        if(!this.isUpdated & this.file == null) {
          $('#qid').modal('hide');
          return;
        } else {
          if(this.qID.length != 11) {
            this.qIDError = true;
            return;
          }
          else {
            this.qIDError = false;
          }
        }
        this.popupLoading = true;
        const pdfFile = this.pdfSrc;
        const formData = new FormData();
        formData.append('q_id', this.qID);
        formData.append('client_id', this.clientID);
        if (this.file != null) {
          await formData.append('pdf', this.file);
        }
        let headers = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };
        this.$http.post(
          "/backend/clients/updateQID",
              formData,
            { headers }
          ).then(function(data) {
            this.popupLoading = false;
            switch (data.status) {
              case 200:
              $('#qid').modal('hide');
              this.success(this.$t("success"));
              this.getClients();
                break;
            }
          },
          (err) => {
            this.popupLoading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          });
      },
      updateEndDate: async function() {
        this.popupLoading = true;
        let headers = {
          Authorization: "Bearer " + sessionStorage.getItem("token"),
        };
        this.$http
          .patch(
            "/backend/clients/updateEndDate",
            {
            end_at : this.endDate +' 00:00:00',
            subscription_id: this.subscription.id
            },
            { headers }
          )
  
          .then(function(data) {
            this.popupLoading = false;
            switch (data.status) {
              case 200:
              this.success(this.$t("success"));
              var index = this.temp.findIndex(item => item.id === this.subscription.id);
              this.temp[index] = data.body;
              this.isEditEndDate =false;
              this.isAdd = false;
                break;
            }
          },
          (err) => {
            this.popupLoading = false;
            switch (err.status) {
              case 500:
                this.error(this.$t("canNotAccess"));
                break;
              case 401:
                this.error(this.$t("verificationProcessError"));
                localStorage.clear();
                sessionStorage.clear();
                this.$router.push("/login");
                break;
              default:
                this.error(this.$t("unexpectedError"));
                break;
            }
          });
      },
      getLang() {
        return localStorage.getItem('lang');
      },
      addClient: function() {
        const panel1Handle = this.$showPanel({
          component: () => import("../../views/clients/AddClient.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            reload: this.getClients,
          },
        });
      },
      editClient(client) {
        const panel1Handle = this.$showPanel({
          component: () => import("../../views/clients/EditClient.vue"),
          openOn: "right",
          width: 700,
          disableBgClick: true,
          props: {
            client: client,
            reload: this.getClients,
          },
        });
        panel1Handle.promise.then((result) => {});
      },
      error: function(message) {
        this.$toast.error(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      warning: function(message) {
        this.$toast.warning(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      success: function(message) {
        this.$toast.success(message, {
          position: "top-right",
          timeout: 3000,
          closeOnClick: true,
          draggable: true,
          draggablePercent: 0.6,
          showCloseButtonOnHover: false,
          hideProgressBar: true,
          closeButton: "button",
          icon: true,
          rtl: false,
        });
      },
      display() {
        this.isYVisible = true;
      },
      hide() {
        this.isYVisible = false;
      },
      subscribe: function() {
          this.loading = true;
          
          let headers = {
            Authorization: "Bearer " + sessionStorage.getItem("token"),
          };
          this.$http.post("/backend/clients/subscribe", {
            subscription_type_id: this.subscriptionType,
            coach_id: this.coach,
            client_id: this.clientID,
            start_at: this.startDate == '' ? '' : this.startDate+' 00:00:00'
          }, {
            headers
          }).then(function(data) {
              this.loading = false;
              switch (data.status) {
                case 201:
                  this.isAdd = false;
                  this.temp.unshift(data.body);
                  break;
              }
            },
            (err) => {
              this.loading = false;
              switch (err.status) {
                case 500:
                  this.error(this.$t("canNotAccess"));
                  break;
                case 401:
                  this.error(this.$t("verificationProcessError"));
                  localStorage.clear();
                  sessionStorage.clear();
                  this.$router.push("/login");
                  break;
                default:
                  this.error(this.$t("unexpectedError"));
                  break;
              }
            });
        },
    },
  };
</script>
<style scoped>
  .link:hover {
    cursor: pointer;
    /* Change the cursor to a pointer on hover */
    text-decoration: underline;
    /* Optionally, add an underline effect */
    color: blue;
  }

  .file-upload-container {
    height: 150px;
    border: 2px dashed #ccc;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    cursor: pointer;
  }

  .file-input {
    display: none;
  }

  .fa-file-upload {
    font-size: 36px;
    color: #888;
  }

  .pdf-shadow-layer {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background: rgba(0, 0, 0, 0.5);
    /* Adjust opacity as needed */
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.5);
    /* Add box shadow for a subtle effect */
    z-index: 98;
    /* Place the shadow layer behind the PDF viewer */
  }

  .pdf-container {
    position: relative;
    text-align: center;
  }

  .pdf-icons {
    position: absolute;
    top: 100px;
    left: 50%;
    transform: translateX(-50%);
    z-index: 99;
  }

  .pdf-viewer-container {
    border: 2px dashed gray;
    margin-top: 20px;
    padding: 10px;
    /* Optional: Add padding for better appearance */
  }

  .pdf-viewer {
    width: 100%;
    height: 500px;
    /* Adjust the height as needed */
  }
</style>