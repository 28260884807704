// proxyConfig.js

const backendProxy = {
    target: 'http://127.0.0.1:8000',
    changeOrigin: true,
    pathRewrite: {
      '^/backend': '',
    },
  };
  
  const pdfProxy = {
    target: 'C:/',
    changeOrigin: true,
    pathRewrite: {
      '^/pdf': '',
    },
  };
  
  module.exports = {
    backendProxy,
    pdfProxy,
  };
  